export default class Service {
sendHttp(url,method='GET',body=null,isJson=false){
    //url='https://erp.mizongkeji.com/mzspace/'+url;
    url='/mzspace/'+url;
    var init={};
    init.method=method;
    if(body!=null)
    init.body=body;
    //var token=localStorage.getItem('token');
    if(isJson){
        init.headers={
            "content-type":'application/json; charset=UTF-8',
           // "token":token
        };
    }//else{
    //     init.headers={
    //        // "token":token
    //     };
    // }
    return fetch(url,init).then(res=>{
        if(res.headers.get('login')=='false'){
            location.href="/login";
        }
        if(res.status==200){
            return res;
        }
    });
}

}